.horFlex {
	width: 100%;
	min-height: 600px;
	max-height: 100%;
}

@media (min-height: 1024px) {
	.horFlex {
		height: 1024px;
	}
}

@media (max-height: 760px) {

	.horFlex {
		.regFiled {
			.titleProd {
				padding: 30px 54px 18px 0;
			}
			.titleProd {
				font-size: 24px;
			}
			.aboutProd {
				font-size: 28px;
			}
			.forProd {
				font-size: 18px;
				margin-bottom: 32px;
			}
		}

		.bkimageF {
			bottom: 10px;
		}

		.buttonsLR {
			bottom: 10px;
		}
	}
}


@media (min-width: 1440px) {
	.horFlex {
		width: 100%;

		.vertFlex {
			width: 625px;
		}
		.regFiled {
			.titleProd {
				font-size: 30px;
				padding: 121px 0 54px 0;

				.logoname {
					margin-top: 5px;
				}
			}

			.aboutProd {
				font-size: 47px;
				width: auto;
				line-height: 55.18px;
			}

			.forProd {
				font-size: 22px;
				line-height: 25.83px;
				width: auto;
			}
		}

		.frame_reclam {
			margin: 68px 36px 68px 0;
			width: 100%;
			height: calc(100% - 68px - 68px);
			max-height: 888px;
			border-radius: 16px;
	      	}
		.bkimageF {
			left: 340px;
		}

		.buttonsLR {
			right: 375px;
			bottom: 45px;
			left: calc((100% - (625px + 36px) - 90px - 12px) / 2 + 625px);
		}
	}
}

@media (min-width: 1920px) {
	.horFlex {
		width: 1920px;
		.vertFlex {
			width: 816px;
		}
		.regFiled {
			margin-left: 56px;

			.titleProd {
				font-size: 32px;
			}
		}

		.frame_reclam {
			margin: 56px 56px 56px 0;
		}

		.bkimageF {
			left: 531px;
		}
		.buttonsLR {
			bottom: 56px;
			left: calc((100% - (816px + 56px) - 90px - 12px) / 2 + 816px);
		}

	}

}
